/*!
 * Krajee SVG Theme styling for bootstrap-star-rating.
 * This file must be loaded after 'star-rating.css'.
 *
 * bootstrap-star-rating v4.1.2
 * http://plugins.krajee.com/star-rating
 *
 * Author: Kartik Visweswaran
 * Copyright: 2013 - 2021, Kartik Visweswaran, Krajee.com
 *
 * Licensed under the BSD 3-Clause
 * https://github.com/kartik-v/bootstrap-star-rating/blob/master/LICENSE.md
 */
.theme-krajee-svg .krajee-icon,
.theme-krajee-svg .krajee-icon-clear {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover
}

.theme-krajee-svg .clear-rating,
.theme-krajee-svg .rating-stars {
    font-size: 5px
}

.theme-krajee-svg .krajee-icon-clear {
    background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20id%3D%22svg2%22%20xmlns%3Ardf%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2F02%2F22-rdf-syntax-ns%23%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20158.81%20158.81%22%20version%3D%221.1%22%3E%0D%0A%3Cpath%20id%3D%22path4%22%20style%3D%22fill%3A%23aaa%22%20stroke-linejoin%3D%22round%22%20d%3D%22m155.06%2C79.438c0%2C41.799-33.885%2C75.684-75.684%2C75.684s-75.684-33.885-75.684-75.684%2C33.885-75.684%2C75.684-75.684%2C75.684%2C33.885%2C75.684%2C75.684z%22%20stroke%3D%22%23aaa%22%20stroke-linecap%3D%22round%22%20fill%3D%22none%22%2F%3E%0D%0A%3Cpath%20id%3D%22rect3139%22%20style%3D%22fill%3A%23fff%22%20d%3D%22m37.216%2C64.443v28.67h88.24v-28.67h-88.24z%22%2F%3E%0D%0A%3C%2Fsvg%3E')
}

.theme-krajee-svg .filled-stars .krajee-icon-star {
    background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20fill%3D%22%23fde16d%22%20stroke%3D%22%23777777%22%20d%3D%22M20.6%2011l-4.6-10.5-4.6%2010.5h-10.8l7.8%207.9-3%2012.1%2010.6-6%2010.6%206-3-12.1%207.8-7.9z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E')
}

.theme-krajee-svg.rating-xl .krajee-icon,
.theme-krajee-svg.rating-xl .krajee-icon-clear {
    width: 3rem;
    height: 3rem
}

.theme-krajee-svg.rating-lg .krajee-icon,
.theme-krajee-svg.rating-lg .krajee-icon-clear {
    width: 2.5rem;
    height: 2.5rem
}

.theme-krajee-svg.rating-md .krajee-icon,
.theme-krajee-svg.rating-md .krajee-icon-clear {
    width: 2rem;
    height: 2rem
}

.theme-krajee-svg.rating-sm .krajee-icon,
.theme-krajee-svg.rating-sm .krajee-icon-clear {
    width: 1.5rem;
    height: 1.5rem
}

.theme-krajee-svg.rating-xs .krajee-icon,
.theme-krajee-svg.rating-xs .krajee-icon-clear {
    width: 1rem;
    height: 1rem
}

.theme-krajee-svg .krajee-icon-clear:hover {
    background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20id%3D%22svg2%22%20xmlns%3Ardf%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2F02%2F22-rdf-syntax-ns%23%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20158.81%20158.81%22%20version%3D%221.1%22%3E%0D%0A%3Cpath%20id%3D%22path4%22%20style%3D%22fill%3A%23843534%22%20stroke-linejoin%3D%22round%22%20d%3D%22m155.06%2C79.438c0%2C41.799-33.885%2C75.684-75.684%2C75.684s-75.684-33.885-75.684-75.684%2C33.885-75.684%2C75.684-75.684%2C75.684%2C33.885%2C75.684%2C75.684z%22%20stroke%3D%22%23843534%22%20stroke-linecap%3D%22round%22%20fill%3D%22none%22%2F%3E%0D%0A%3Cpath%20id%3D%22rect3139%22%20style%3D%22fill%3A%23fff%22%20d%3D%22m37.216%2C64.443v28.67h88.24v-28.67h-88.24z%22%2F%3E%0D%0A%3C%2Fsvg%3E')
}

.theme-krajee-svg .empty-stars .krajee-icon-star {
    background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20stroke%3D%22%23777777%22%20d%3D%22M20.6%2011l-4.6-10.5-4.6%2010.5h-10.8l7.8%207.9-3%2012.1%2010.6-6%2010.6%206-3-12.1%207.8-7.9z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E')
}

.theme-krajee-svg .filled-stars .krajee-icon-heart {
    background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%23fde16d%22%20stroke%3D%22%23777777%22%20stroke-width%3D%220.04em%22%20d%3D%22M12%2021.35l-1.45-1.32c-5.15-4.67-8.55-7.75-8.55-11.53%200-3.08%202.42-5.5%205.5-5.5%201.74%200%203.41.81%204.5%202.09%201.09-1.28%202.76-2.09%204.5-2.09%203.08%200%205.5%202.42%205.5%205.5%200%203.78-3.4%206.86-8.55%2011.54l-1.45%201.31z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E')
}

.theme-krajee-svg .empty-stars .krajee-icon-heart {
    background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20stroke%3D%22%23777777%22%20stroke-width%3D%220.04em%22%20d%3D%22M12%2021.35l-1.45-1.32c-5.15-4.67-8.55-7.75-8.55-11.53%200-3.08%202.42-5.5%205.5-5.5%201.74%200%203.41.81%204.5%202.09%201.09-1.28%202.76-2.09%204.5-2.09%203.08%200%205.5%202.42%205.5%205.5%200%203.78-3.4%206.86-8.55%2011.54l-1.45%201.31z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E')
}

.theme-krajee-svg.rating-rtl.is-heart .filled-stars {
    right: .02em
}

.theme-krajee-svg.rating-rtl.is-heart .empty-stars {
    margin-right: .01em
}